import React, { Component } from "react"
import AuthWrapper from "../components/authPages/AuthWrapper"

interface Props {
  location: any
}

export default class DashboardPage extends Component<Props> {
  render() {
    return <AuthWrapper component="Dashboard" location={this.props.location} />
  }
}
